import { create } from "zustand";
import { getBlogs, getBlogById } from "../NetworkServices/services";

const useBlogStore = create((set, get) => ({
  blogs: [],
  totalCount: 0,
  currentPage: 1,
  searchQuery: "",
  loading: false,
  error: null,
  currentBlog: null,
  viewedBlogs: {}, // Cache for viewed blog posts

  setSearchQuery: (query) => set({ searchQuery: query }),
  setCurrentPage: (page) => set({ currentPage: page }),

  fetchBlogs: async (page = 1, limit = 12) => {
    set({ loading: true, error: null });
    try {
      const response = await getBlogs(page, limit);
      console.log(response);
      if (response?.status === 200) {
        set({
          blogs: response.data.data,
          totalCount: response.data.totalCount,
          loading: false,
        });
      } else {
        set({
          error: response?.data?.message || "Failed to fetch blogs",
          loading: false,
          blogs: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Blog fetch error:", error);
      set({
        error: error?.message || "Failed to fetch blogs",
        loading: false,
        blogs: [],
        totalCount: 0,
      });
    }
  },

  fetchBlogById: async (id) => {
    // First check cache
    const cachedBlog = get().viewedBlogs[id];
    if (cachedBlog) {
      set({ currentBlog: cachedBlog });
      return;
    }

    set({ loading: true, error: null, currentBlog: null });
    try {
      const response = await getBlogById(id);
      if (response?.status === 200 && response?.data) {
        const blog = response.data.data;
        set((state) => ({
          currentBlog: blog,
          viewedBlogs: { ...state.viewedBlogs, [id]: blog },
          loading: false,
        }));
      } else {
        set({
          error: response?.data?.message || "Failed to fetch blog post",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Blog fetch error:", error);
      set({
        error: error?.message || "Failed to fetch blog post",
        loading: false,
      });
    }
  },

  clearCurrentBlog: () => set({ currentBlog: null, error: null }),
}));

export default useBlogStore;
