import "./App.css";
import React from "react";
import IndexHome from "./pages/home";
import IndexAbout from "./pages/about";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IndexContact from "./pages/contact";
import IndexLoans from "./pages/loans";
import IndexBlog from "./pages/blog";
import IndexBlogPost from "./pages/blog/blogPost";
import IndexAccount from "./pages/account";
import IndexCurrentAccount from "./pages/currentAccount";
import IndexSavingAccount from "./pages/savingsAccount";
import IndexMainChannels from "./pages/channels/mainChannels";
import IndexMobileBankingChannels from "./pages/channels/mobileBanking";
import IndexAgencyBankingChannels from "./pages/channels/agencyBanking";
import IndexUssdBankingChannels from "./pages/channels/ussdBanking";
import IndexPolicy from "./pages/privacy/policy";
import IndexTerms from "./pages/privacy/termsOfService";
import IndexBusinessBanking from "./pages/businessBanking";
import IndexBetaPage from "./pages/betaPage";
import AppToast from "./AppToast";

const App = () => {
	return (
		<BrowserRouter>
			<AppToast />
			<div className="inter_face">
				<snapshot-interface client_id="67e2979f14fef187fde0edce" />
			</div>
			<Routes>
				<Route path="/" index element={<IndexHome />} />
				<Route path="contact" element={<IndexContact />} />
				<Route path="about" element={<IndexAbout />} />
				<Route path="loans" element={<IndexLoans />} />
				<Route path="account" element={<IndexAccount />} />
				<Route path="current-account" element={<IndexCurrentAccount />} />
				<Route path="saving-account" element={<IndexSavingAccount />} />
				<Route path="main-channels" element={<IndexMainChannels />} />
				<Route path="mobile-banking" element={<IndexMobileBankingChannels />} />
				<Route path="agency-banking" element={<IndexAgencyBankingChannels />} />
				<Route path="ussd-banking" element={<IndexUssdBankingChannels />} />
				<Route path="privacy-policy" element={<IndexPolicy />} />
				<Route path="terms-service" element={<IndexTerms />} />
				<Route path="business-banking" element={<IndexBusinessBanking />} />
				<Route path="beta-request" element={<IndexBetaPage />} />
				<Route path="blog" element={<IndexBlog />} />
				<Route path="blog/:id" element={<IndexBlogPost />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
