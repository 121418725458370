import axios from "axios";
const conn_url = "https://zikora-node.herokuapp.com";

const zikora_endpoint = process.env.REACT_APP_ZIKORA_ENDPOINT;

if (!zikora_endpoint) {
  console.error("REACT_APP_ZIKORA_ENDPOINT environment variable is not set");
  throw new Error("REACT_APP_ZIKORA_ENDPOINT environment variable is not set");
}

const config = {
  headers: {
    "content-type": "application/json",
  },
};

export const sendEmail = async (data) => {
  try {
    const uri = `${conn_url}/zikora-api/v1/send-email-to-zkr-support`;
    const response = await axios.post(uri, data, config);
    return { data: response?.data, status: response?.status };
  } catch (error) {
    return { data: error.response?.data, status: error.response?.status };
  }
};

export const getBlogs = async (page = 1, limit = 12) => {
  try {
    const uri = `${zikora_endpoint}/zikora-api/v1/admin/get-all-blogs?page=${page}&limit=${limit}`;
    const response = await axios.get(uri, config);
    return { data: response?.data, status: response?.status };
  } catch (error) {
    return { data: error.response?.data, status: error.response?.status };
  }
};

export const getBlogById = async (id) => {
  try {
    const uri = `${zikora_endpoint}/zikora-api/v1/admin/get-blog-by-id/${id}`;
    const response = await axios.get(uri, config);
    return { data: response?.data, status: response?.status };
  } catch (error) {
    return { data: error.response?.data, status: error.response?.status };
  }
};
