import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useBlogStore from "../../../store/blogStore";
import styled from "@emotion/styled";
import Header from "../../../components/header";
import blogPlaceholder from "../../../images/blog_placeholder.png";

const ContentContainer = styled(Box)`
  background-color: #fbfbfb;
  border-radius: 12px;
  overflow: hidden;
`;

const ImageContainer = styled(Box)`
  padding: 2rem 4rem 0;
`;

const MarkdownContainer = styled(Box)`
  font-family: "Circular", -apple-system, BlinkMacSystemFont, sans-serif;
  color: #1a1a1a;
  padding: 2rem 2rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-top: 2em;
    margin-bottom: 1em;
    text-align: center;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.7;
    margin-bottom: 1.5em;
    color: #333;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  }

  ul,
  ol {
    margin: 1.5em auto;
    padding-left: 2em;
    max-width: 680px;
    list-style-type: disc;

    li {
      margin-bottom: 0.5em;
      font-size: 1.125rem;
      line-height: 1.7;
      color: #333;
      padding-left: 0.5em;

      &::marker {
        color: #666;
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  blockquote {
    margin: 2em auto;
    padding: 1.5em 2em;
    border-left: 4px solid #e0e0e0;
    background-color: #fff;
    font-style: italic;
    max-width: 680px;

    p {
      margin: 0;
      color: #666;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 2.5em auto;
    display: block;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const BlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentBlog, loading, error, fetchBlogById, clearCurrentBlog } =
    useBlogStore();

  useEffect(() => {
    fetchBlogById(id);
    return () => clearCurrentBlog();
  }, [id, fetchBlogById, clearCurrentBlog]);

  const handleRetry = () => {
    fetchBlogById(id);
  };

  const handleBack = () => {
    navigate("/blog");
  };

  if (loading) {
    return (
      <>
        <Header />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="calc(100vh - 64px)"
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="calc(100vh - 64px)"
          p={3}
        >
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
          <Button variant="contained" onClick={handleRetry} sx={{ mt: 2 }}>
            Retry
          </Button>
        </Box>
      </>
    );
  }

  if (!currentBlog) return null;

  return (
    <Box
      sx={{
        background: "rgba(232, 236, 227, 0.33)",
        paddingTop: "2rem",
        fontFamily: "Circular Std",
        minHeight: "100vh",
      }}
    >
      <div className="px-[5rem]">
        <Header />
      </div>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Box sx={{ maxWidth: "1000px", mx: "auto", px: { xs: 2, md: 0 } }}>
          <Breadcrumbs sx={{ mb: 6 }}>
            <Link
              component="button"
              variant="body2"
              onClick={handleBack}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#666",
                textDecoration: "none",
                "&:hover": { color: "#333" },
              }}
            >
              <ArrowBackIcon sx={{ mr: 0.5, fontSize: 18 }} />
              Back to Blog
            </Link>
          </Breadcrumbs>

          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: { xs: "2rem", md: "2.5rem" },
              fontWeight: 700,
              color: "#1a1a1a",
              mb: 4,
              fontFamily: "'Circular', sans-serif",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            {currentBlog.blog_title}
          </Typography>

          <ContentContainer>
            <ImageContainer>
              <Box
                component="img"
                src={blogPlaceholder}
                alt={currentBlog.blog_title}
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: "8px",
                }}
              />
            </ImageContainer>

            <MarkdownContainer>
              <ReactMarkdown>{currentBlog.blog_content}</ReactMarkdown>
            </MarkdownContainer>
          </ContentContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default BlogPost;
