import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Pagination,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../../components/header";
import BlogCard from "../../components/BlogCard";
import useBlogStore from "../../store/blogStore";

const ITEMS_PER_PAGE = 12;

const Main = () => {
  const {
    blogs,
    totalCount,
    currentPage,
    searchQuery,
    loading,
    error,
    fetchBlogs,
    setSearchQuery,
    setCurrentPage,
  } = useBlogStore();

  useEffect(() => {
    fetchBlogs(currentPage, ITEMS_PER_PAGE);
  }, [currentPage, fetchBlogs]);

  const filteredBlogs = blogs.filter((blog) =>
    blog.blog_title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        background: "rgba(232, 236, 227, 0.33)",
        paddingTop: "2rem",
        fontFamily: "Circular Std",
      }}
    >
      <div className="px-[5rem]">
        <Header />
      </div>
      <Container maxWidth="lg" sx={{ py: { xs: 4, md: 6 } }}>
        <Typography
          variant="h2"
          component="h1"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: { xs: "2rem", md: "2.5rem" },
            mb: { xs: 3, md: 4 },
            color: "#1a1a1a",
          }}
        >
          Press and Media
        </Typography>
        <Typography
          variant="h6"
          component="h2"
          align="center"
          color="text.secondary"
          sx={{ mb: { xs: 4, md: 6 } }}
        >
          The latest industry news, interviews, technologies, and resources.
        </Typography>

        <Box sx={{ mb: { xs: 6, md: 8 } }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search articles..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#66A681" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: 600,
              mx: "auto",
              display: "block",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#fbfbfb",
                transition: "all 0.2s ease-in-out",
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "#66A681",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#66A681",
                },
              },
              "& .MuiOutlinedInput-input": {
                padding: "16px 14px",
                "&::placeholder": {
                  color: "#666",
                  opacity: 0.8,
                },
              },
            }}
          />
        </Box>

        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress sx={{ color: "#66A681" }} />
          </Box>
        ) : error ? (
          <Box textAlign="center" my={4}>
            <Typography color="error" gutterBottom>
              {error}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please check your environment configuration and try again.
            </Typography>
          </Box>
        ) : (
          <>
            {filteredBlogs.length > 0 ? (
              <Grid container spacing={3}>
                {filteredBlogs.map((blog) => (
                  <Grid item key={blog.id} xs={6} md={4}>
                    <BlogCard blog={blog} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography textAlign="center" color="text.secondary">
                No blogs found.
              </Typography>
            )}

            {totalPages > 1 && (
              <Box display="flex" justifyContent="center" mt={8}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  size="large"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "#666",
                      "&.Mui-selected": {
                        backgroundColor: "#66A681",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#558c6b",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "rgba(102, 166, 129, 0.1)",
                      },
                    },
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default Main;
