import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import blogPlaceholder from "../images/blog_placeholder.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const stripMarkdown = (text) => {
  return text.replace(/[#*<>[\]()!\n]/g, "").trim();
};

const BlogCard = ({ blog }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${blog.id}`);
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        borderRadius: "12px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        position: "relative",
        backgroundColor: "#fff",
        overflow: "hidden",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
          "& .arrow-icon": {
            transform: "translateX(4px)",
          },
        },
      }}
    >
      <Box sx={{ p: 2, backgroundColor: "#fbfbfb" }}>
        <CardMedia
          component="img"
          height="200"
          image={blogPlaceholder}
          alt={blog.blog_title}
          sx={{
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#fbfbfb",
          "&:last-child": {
            pb: 3,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: 2,
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 600,
              fontSize: "1.125rem",
              lineHeight: 1.4,
              color: "#1a1a1a",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {blog.blog_title}
          </Typography>
          <ArrowOutwardIcon
            className="arrow-icon"
            sx={{
              color: "#66A681",
              transition: "transform 0.3s ease-in-out",
              fontSize: "20px",
              flexShrink: 0,
              mt: 0.5,
            }}
          />
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "#666",
            lineHeight: 1.6,
            fontSize: "0.875rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {stripMarkdown(blog.blog_content)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BlogCard;
