import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { RxHamburgerMenu } from "react-icons/rx";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export default function MobileNav() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [activeTab, setActiveTab] = React.useState("personal");

  const nav = [
    { name: "Home", link: "/", children: [] },
    { name: "Online Banking", link: "", children: [] },

    {
      name: "Channels",
      link: "",
      children: [
        { name: "Banking Channel", link: "/main-channels" },
        { name: "Mobile Banking", link: "/mobile-banking" },
        { name: "Agency Banking", link: "/agency-banking" },
        { name: "USSD Banking", link: "/ussd-banking" },
      ],
    },
    { name: "Loans", link: "/loans/", children: [] },
    {
      name: "Accounts",
      link: "",
      children: [
        { name: "Account", link: "/account" },
        { name: "Current Account", link: "/current-account" },
        { name: "Saving Account", link: "/saving-account" },
      ],
    },
    { name: "Blog", link: "/blog", children: [] },
    { name: "About", link: "/about/", children: [] },
    { name: "Contact", link: "/contact/", children: [] },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box sx={{ width: "82vw", marginTop: "2rem" }} role="presentation">
      <List>
        {nav.map((text, index) => (
          <>
            {text.name === "Online Banking" ? (
              <Accordion
                sx={{
                  boxShadow: "none",
                  bgcolor: "#FFFFFF",
                  // mb: 0,
                  borderRadius: "4px",
                }}
              >
                <AccordionSummary
                  expandIcon={<IoIosArrowDown sx={{ color: "white" }} />}
                  sx={{
                    color: "black",
                    "& .MuiAccordionSummary-content": {
                      my: 0.5,
                    },
                  }}
                >
                  <Typography>Online Banking</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Box sx={{ bgcolor: "white", p: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        mb: 2,
                        borderRadius: "3px",
                        border: "1px solid #66A681",
                      }}
                    >
                      <Box
                        onClick={() => setActiveTab("personal")}
                        sx={{
                          flex: 1,
                          py: 0.75,
                          textAlign: "center",
                          cursor: "pointer",
                          bgcolor:
                            activeTab === "personal" ? "white" : "transparent",
                          color: "#66A681",
                          fontSize: "13px",
                          fontWeight: 500,
                          transition: "all 0.2s ease",
                        }}
                      >
                        Personal
                      </Box>
                      <Box
                        onClick={() => setActiveTab("corporate")}
                        sx={{
                          flex: 1,
                          py: 0.75,
                          textAlign: "center",
                          cursor: "pointer",
                          bgcolor:
                            activeTab === "corporate" ? "white" : "transparent",
                          color: "#66A681",
                          fontSize: "13px",
                          fontWeight: 500,
                          transition: "all 0.2s ease",
                        }}
                      >
                        Corporate
                      </Box>
                    </Box>

                    {activeTab === "personal" ? (
                      <Box>
                        <Button
                          component="a"
                          href="https://zikoramfb.qoreonline.com/login"
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            width: "100%",
                            bgcolor: "#66A681",
                            color: "white",
                            textTransform: "none",
                            mb: 2,
                            py: 1,
                            borderRadius: "3px",
                            fontSize: "13px",
                            fontWeight: 500,
                            "&:hover": {
                              bgcolor: "#558c6b",
                            },
                          }}
                        >
                          Login
                        </Button>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            px: 0.5,
                          }}
                        >
                          <Link
                            component="a"
                            href="https://zikoramfb.qoreonline.com/onboarding/type"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#666",
                              textDecoration: "none",
                              fontSize: "12px",
                            }}
                          >
                            Register
                          </Link>
                          <Box
                            sx={{
                              width: "1px",
                              height: "12px",
                              bgcolor: "#E0E0E0",
                              mx: 1,
                            }}
                          />
                          <Link
                            component="a"
                            href="https://zikoramfb.qoreonline.com/onboarding/bvn-verification"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#666",
                              textDecoration: "none",
                              fontSize: "12px",
                            }}
                          >
                            Open New Account
                          </Link>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          textAlign: "center",
                          color: "#666",
                          fontSize: "13px",
                          py: 2,
                        }}
                      >
                        Coming Soon
                      </Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : text.children === 0 ? (
              <ListItem key={text.name} disablePadding>
                <Link className="w-[100%]" to={text.link}>
                  <ListItemButton>
                    <p>{text.name}</p>
                  </ListItemButton>
                </Link>
              </ListItem>
            ) : (
              <Accordion sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<IoIosArrowDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{text.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {text.children.map((child) => (
                    <ListItem key={child.name} disablePadding>
                      <Link className="w-[100%]" to={child.link}>
                        <ListItemButton>
                          <p>{child.name}</p>
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  ))}
                </AccordionDetails>
                <Divider />
              </Accordion>
            )}
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "none",
        "@media (max-width: 639px)": {
          display: "block",
          fontFamily: "Circular Std",
        },
      }}
    >
      <React.Fragment key="right">
        <Box
          sx={{
            display: "none",
            "@media (max-width: 639px)": {
              display: "block",
              cursor: "pointer",
            },
          }}
          onClick={toggleDrawer("right", true)}
          className="sm:text-3xl"
        >
          <RxHamburgerMenu />
        </Box>
        <Drawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </Box>
  );
}
